import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { PersonExplorerLayout } from '../../components/entities/person-explorer-layout';

export default createGatsbyPageComponent('Person', { layout: PersonExplorerLayout });

export const personQuery = graphql`
  query PersonQuery($id: ID!) {
    platform {
      person(id: $id) {
        id slug name path __typename
        bio { text }
        badge
        photo { id url url2x }
        position
        hasConnectedTopics
        positions { id name }
        twitter
        linkedin
        color { value }
        openGraph { 
          title description { plain }
          image { url url2x thumbnails { large { url url2x } } }
        }
        organisation { id name slug }
        organisations {
          id slug name path __typename
          type { id name slug }
          openGraph {
            image { id url url2x }
          }
        }
        relatedEvents {
          id name title slug path __typename _schema { label pluralLabel }
          type { id name slug }
          openGraph { 
            image { id url url2x } 
          }
        }
        pages {
          id name slug path __typename _schema { label pluralLabel }
          openGraph { 
            image { id url url2x } 
          }
        }
        topicExpertise {
          __typename
          typeLabel
          badge
          name
          slug
          levelOfExpertise {
            name
            slug
          }
          edition { name }
          topic {
            trend { slug}
            __typename
            outlineNumber
            id path name slug
            openGraph { 
              title description { plain }
              image { url url2x thumbnails { medium { url url2x } } }
            }
          }
        }
      }
    }
  }
`;
