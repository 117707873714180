import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Person } from '@shapeable/gesda-types';
import { get } from 'lodash';
import { ComponentPropsProvider, SiteHeaderLayout, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, SliceLayoutBoundary, SvgCanvasViewBoxDefinition, MetaSectionEntity } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { transformPeople, transformPeopleEvents } from '../../utils/transforms';
import { RadarChart, useRadar } from '@shapeable/gesda-ui';
const cls = classNames('person-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type PersonExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity: Person;
};

export const PersonExplorerLayoutDefaultProps: Omit<PersonExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const MetaStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    opacity: 0.4;
    filter: saturate(0.4);
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Radar: styled(RadarChart)`${RadarChartStyles}`,
};

export const PersonExplorerLayout: Shapeable.FC<PersonExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  // need to fix the below tranforms
  const entityWithBadge = transformPeople(entity);
  const transformedEntity = transformPeopleEvents(entityWithBadge);

  const topicExpertise = (entity.topicExpertise || []).map((topicExpertise) => ({
    ...topicExpertise,
    path: topicExpertise.topic?.path,
  }));

  const metaItems: MetaSectionEntity[] = useMemo(() => {
    const sections = [
      { items: topicExpertise.filter(ex => ex.levelOfExpertise?.slug === 'anticipation-committee-chair'), title: 'Anticipation Committee Chair', label: 'Pulse of Science' },
      { items: topicExpertise.filter(ex => ex.levelOfExpertise?.slug === 'anticipation-committee-member'), title: 'Anticipation Committee member', label: 'Pulse of Science' },
    ];

    return sections.filter(section => section.items.length > 0);
  }, [topicExpertise]);
  
  const radar = useRadar();

  let initialViewBox: SvgCanvasViewBoxDefinition;
  const trend = get(entity, 'topicExpertise[0].topic.trend');
  
  if (trend) {
    initialViewBox = radar.viewboxPresets?.[trend?.slug];
  }

  return (
    <ExplorerProvider>
    <ComponentPropsProvider value={{ 
      PersonCell: {
        size: 'banner',
      },
      PersonViewLayout: {
        relatedContentLabel: 'Related Radar Content',
      },
      PersonMetaLayout: {
        metaItems: metaItems,
        displayOrgs: false,
      }
    }}>
    <My.Container>
      <My.Header />
      <SliceLayoutBoundary boundary='none' >
        <My.Layout 
          entity={transformedEntity}
          className={cls.name(className)}

          dataViz={
            !!initialViewBox &&
            <My.Radar 
              fadeSize={!!initialViewBox && 44} 
              initialViewBox={initialViewBox} 
              isInteractive={!initialViewBox}
            />
          }
        /> 
      </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </ExplorerProvider>
  )
};

PersonExplorerLayout.defaultProps = PersonExplorerLayoutDefaultProps;
PersonExplorerLayout.cls = cls;